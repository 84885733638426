<template>
  <div class="page redirect">
    <div class="container">
      <div v-if="loading" class="loading">
        <lottie :options="lottieOptions" :height="100" :width="100"></lottie>
        <p class="loading__text">QR코드를 분석하는 중입니다</p>
      </div>
    </div>
  </div>
</template>

<script>
import Lottie from 'vue-lottie';
import LottieLoading from '@/assets/lottie/loading-primary.json';
import { isValidHttpURL } from '@/helpers';
import config from '@/config';
import axios from 'axios';

const CodePrefix = {
  TICKET: "T",
  STORE: "S",
};

export default {
  components: {
    Lottie,
  },
  props: {
    code: String,
  },

  data() {
    return {
      lottieOptions: {
        animationData: LottieLoading,
      },

      loading: false,
    };
  },

  // mixins: [logMixin],

  created() {
    this.loading = true;

    this.checkCode();

    // setTimeout(() => {
    //   this.loading = false;
    // }, 100);
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.auth.gid > 0;
    },

    decoded() {
      return decodeURIComponent(this.code);
    },
  },

  methods: {
    moveToURL(href) {
      console.log( "moveToURL", href );
      
      location.replace(href);
    },

    handleDynamicLink(urlString) {
      console.log( "handleDynamicLink", urlString );

      const url = new URL(urlString);
      const link = url.searchParams.get("link");

      if (!config.get("appURL")) {
        this.moveToURL(link);
        return;
      }

      if (this.wni.isNative) {
        if (link) {
          try {
            this.moveToURL(new URL(link).pathname);
          } catch (e) {
            console.error(e);
            this.moveToURL("/");
          }
        }
        return;
      }

      url.searchParams.append("isi", config.get("iosAppStoreId"));
      url.searchParams.append("efr", 1);

      this.moveToURL(url.href);
    },

    onUrlCode() {
      console.log( "onUrlCode" );

      const isDynamicLink = this.decoded.startsWith(appURL);
      const urlCode = new URL(this.decoded);
      const isSameOrigin = urlCode.origin === new URL(location.href).origin; //temp

      if (isDynamicLink) {
        return this.handleDynamicLink(this.decoded);
      }

      if (isSameOrigin) {
        if (this.decoded.indexOf(`${config.siteURL}/r/`) < 0)
          return this.onInvalidCode();

        const target = this.decoded.replace(`${config.siteURL}/r/`, "");
        return this.$router.replace(encodeURIComponent(target));
      }

      return this.onInvalidCode();
    },

    getSpaceBySpaceCode(space_code) {
      console.log( "getSpaceBySpaceCode", space_code );

      return new Promise(async (resolve, reject) => {
        try {
          const res = await axios.get(`https://api.connect.lugstay.com/v1/spaces/code/${space_code}`)
          const { data } = res.data;
          resolve(data);
        }
        catch(e) {
          reject(e);
        }
      });
    },

    onStoreCode() {
      console.log( "onStoreCode" );

      const store_id = this.decoded.substring(1);

      this.getSpaceBySpaceCode(`lugstay.${store_id}`)
        .then((space) => {
          this.moveToURL(`https://link.lugstay.com/?link=https://app.lugstay.com/spaces/${space.id}&apn=com.lugstay.app&isi=1472429427&ibi=com.lugstay.app&ius=com.lugstay.app&efr=1`)
        })
        .finally(() => {
          
        });

      // this.$router.replace("/").then((_) => {
      //   if (!this.isLoggedIn) {
      //     // 로그인을 안했을 경우, 지도 화면으로 가게끔
      //     this.$router.push({
      //       name: "Stores",
      //       query: { autoSearch: "target", sid: store_id },
      //     });
      //     return;
      //   }
      //   if (store_id === "3478") {
      //     this.$router.push({
      //       name: "Stores",
      //       query: { autoSearch: "target", sid: store_id },
      //     });
      //     return;
      //   }

      //   this.$router.push({
      //     name: "RedirectTicketList",
      //     query: { store_id: store_id },
      //   });
      // });
    },

    onTicketCode() {
      console.log( "onTicketCode" );

      this.moveToURL("/");
    },

    onInvalidCode() {
      console.log( "onInvalidCode" );

      this.$store.commit('loading/SET_TRUE');
      this.$store.commit('alert/ADD_ITEM', { message: this.$__t('알 수 없는 QR코드입니다.'), status: 'info' });
      
      this.moveToURL('/');

      this.$store.commit('loading/SET_FALSE');
    },

    checkCode() {
      console.log( "checkCode" );

      try {
        if (!this.decoded) {
          this.onInvalidCode();
          return;
        }

        if (isValidHttpURL(this.decoded)) {
          this.onUrlCode();
          return;
        }

        if (this.decoded.startsWith(CodePrefix.STORE)) {
          this.onStoreCode();
          return;
        }

        if (this.decoded.startsWith(CodePrefix.TICKET)) {
          this.onTicketCode();
          return;
        }

        this.onInvalidCode();
      } catch (e) {
        this.log('error', {
          method: 'checkCode',
          error: e,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page.redirect {
  @include fill-viewport-min-height;

  .loading {
    width: 100%;
    padding: unit(30);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__text {
      color: #a9afb3;
      font-size: unit(14);
      text-align: center;
      line-height: 1.43;
      font-weight: 500;
    }
  }
}
</style>
